<template>
  <div class="flex w-full flex-col justify-center align-items">
    <div
      class="self-center mt-2 mb-2 text-xl font-light text-gray-800 sm:text-2xl dark:text-white"
    >
      Olá! Utilize o menu da esquerda para navegar
    </div>

    <div class="flex justify-center items-center">
      <img class="w-2/5 h-2/5" src="@/assets/img/logo_colorida.png" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
};
</script>
